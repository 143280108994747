<template>
    <div>
        <!-- Header -->
        <section id="page-title">

            <div class="container clearfix">
                <h1>Contact Us</h1>
                <span>Message Us for more Information</span>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
                </ol>
            </div>

        </section>
		<!-- Content 
		============================================= -->
        <Contact/>
    </div>
</template>

<script>
import Contact from '@/components/ContactUs.vue'
export default {
	name: 'ContactUsPage',
	components: {
		Contact
	}
}
</script>